.transicao{
    background-color: rgba(0, 0, 0, 0.685);
    background-image: url("../../Images/avif/mkdg3.avif");
    background-attachment: fixed;
    background-size: cover;
    margin: 0;
    padding: 2em;
    background-blend-mode:color;
    width: 100%;
}

.transicao h1::after{
    display: block;
    content: '';
    width: 100px;
    height: 1px;
    margin: 26px auto 0;
    background-color: #fff;
}

.transicaoB{
    background-color: #ffffff9a;
    background-image: url("../../Images/avif/mkdg3.avif");
    background-attachment: fixed;
    background-size: cover;
    margin: 0;
    padding: 2em;
    background-blend-mode:lighten;
    width: 100%;
}

.transicaoB h1::after{
    display: block;
    content: '';
    width: 100px;
    height: 1px;
    margin: 26px auto 0;
    background-color: black;
}