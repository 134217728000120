.foot{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.925);
    background-image: url("../../Images/avif/mkdg4_1.avif");
    background-blend-mode: color;
}

.footB{
    width: 100%;
    background-color: rgba(255, 255, 255, 0.815);
    background-image: url("../../Images/avif/mkdg4_1.avif");
    background-blend-mode: lighten;
}

.foot__mid{
    background-color: rgba(255, 68, 0, 0.637);
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.logoF{
    width: 60%;
    margin: auto;

}
.foot__mid__direct{
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;

}
.foot__mid__obg{
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5em;

}

  
/* .instafeedfoot{
    width: 100%;
    height: 100%;
    
  } */
    .framedinsta{

    }

  .framedinsta::-webkit-scrollbar {
    width: 10px;
  }
  
  .framedinsta::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .framedinsta::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
  }
  
  .framedinsta::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .framedinstaB::-webkit-scrollbar {
    width: 10px;
  }
  
  .framedinstaB::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .framedinstaB::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
  
  .framedinstaB::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
