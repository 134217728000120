.Containe{
  background-image: url("../src/Images/fundo.webp");
    background-attachment: fixed;
    background-size: cover;
    margin: 0;
    padding: 0;
    background-color: rgba(255, 255, 255, 0.562);
    background-blend-mode: lighten;
    width: 100%;
    height: 100vw;

}
.divisoria {
  position: absolute;
  top: 50em;
  background-color: white;
  height: 80%;
  width: 100%;
}

a{
  text-decoration: none;
}