.fundoPlano{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.925);
    background-image: url("../../Images/avif/mkdg4_1.avif");
    background-blend-mode: color;
}

.planos{
    background-color: #ffffffc7;
    width: 85%;
}

.fundoPlanoB{
    width: 100%;
    background-color: rgba(255, 255, 255, 0.815);
    background-image: url("../../Images/avif/mkdg4_1.avif");
    background-blend-mode: lighten;
}

.planosB{
    background-color: #000000c7;
    width: 85%;
}

.protection-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  