.fundo{
    background-color: #000000da;
    background-image: url("../../Images/avif/mkdg2.avif");
    background-size:cover;
    background-repeat: no-repeat;
    background-position: right;
    background-blend-mode: color;
}
.fundoB{
    background-color: #ffffff9a;
    background-image: url("../../Images/avif/mkdg2.avif");
    background-size:cover;
    background-repeat: no-repeat;
    background-position: right;
    background-blend-mode: lighten;
}
.textoTop{
    /* font-family: 'Play', sans-serif;; */
    font-size: 3em;
    color: white;
}
.textoTopB{
    /* font-family: 'Play', sans-serif;; */
    font-size: 3em;
    color: black;
}
.logo{
    width: 30%;
}
.sociais{
    color: white;
}
.sociaisB{
    color: black;
}
.direita{
    /* background-color: #ffffff8e; */
    width: 100%;
    margin: 0;
}
.esquerda {
  padding-top: 7rem;
}


/* O Interruptor de Claro/Escuro */

.switch {
    /* position: absolute;
    top: 0.5rem;
    right: 0.5rem;
      */ position: relative;
        display: block;
        width: 4rem;
        height: 2.1rem;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
    /* ...existing styles... */
    display: inline-block;
    background-image: url('../../Images/icons/moon.svg'); /* add your icon paths here */
    background-size: cover;
    background-position: center;
    /* margin-left: -26px; this should be the negative value of the width of your icon */
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-image: url('../../Images/icons/sun.svg'); /* add your icon paths here */
  }
  .switch__label{
    text-align: center;
    position: absolute;
    top: 0.3rem;
    right: 4rem;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  